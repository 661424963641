﻿/* These styles are for nested menus. By default Bootstrap caters for just two menu levels.
    Any additional submenus are not displayed. The following overrides enable submenus
    to be nested to any depth.
*/
.dropdown-menu{
    top:auto;
}
.dropdown-menu .dropdown-menu {
    left: 100% !important;
    /*top: auto;*/

    @media (min-width: @grid-float-breakpoint) {
        margin-top: -30px !important;
    }

    @media (max-width: @grid-float-breakpoint) {
        margin-left: 20px;
    }
}

.dropdown .dropdown:hover > .dropdown-menu,
.dropdown .dropdown .dropdown:hover > .dropdown-menu,
.dropdown .dropdown .dropdown .dropdown:hover > .dropdown-menu,
.dropdown .dropdown .dropdown .dropdown .dropdown:hover > .dropdown-menu {
    display: block;
}

.dropdown-menu i {
    line-height: @line-height-base;
}

@media (min-width: @grid-float-breakpoint) {
    .navbar > #layout-navigation .navbar-brand {
        margin-left: -15px;
    }
}
