﻿.widget h1 {
    .h3;
}

.tags, .comment-count {
    margin-right: 20px;
}

html.rtl .tags, html.rtl .comment-count {
    margin-right: 0;
}