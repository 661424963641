@import "site-default.less";
@import "bootswatch/anjea-bootswatch.less";
@import "bootswatch/anjea-variables.less";


ul.portfolio_filters li{
    display:inline;
    padding-right: 2em;
}
.comment-count, #comment-form-beacon, .comment-form, .slog-summary, .anjea-narrow {
    max-width: 970px;
    margin: 0 auto;
}

.slog-feature-image {
    width: 100%;
    margin-top: -20px;
}

.slog-feature-image-thumbnail {
    margin-top: 10px;
}

article.slog {
    max-width: 970px;
    margin: 0 auto;
        font-size: 1.5em;

    h1 {
        font-size: 3em;
    }

    h2 {
        font-size: 2.5em;
        margin-top: 20px;
    }

    h3 {
        margin-top: 20px;
        font-size: 2em;
    }

    h4 {
        margin-top: 20px;
        font-size: 1.8em;
    }

    h5 {
        font-size: 1.5em;
    }

    ul {
        padding-left: 25px;
        list-style: disc outside;

        li {
            margin-bottom: 15px;
        }
    }

    ol {
        padding-left: 40px;
        list-style: decimal;
    }
}
