﻿html.boxed-layout {
    #layout-main-container, #layout-tripel-container, #layout-footer {
        .container;
    }
}

html.fluid-layout {
    #layout-main-container, #layout-tripel-container, #layout-footer {
        /*padding: 0 15px;*/
    }
}

html.fixed-nav.boxed-layout {
    #layout-navigation {
        .container;
    }
}

html.fixed-nav.fluid-layout {
    #layout-navigation {
        padding: 0 15px;
        /*height: @navbar-height; prevents mobile menu from expanding */

    }
}

html.floating-nav {
    #layout-navigation {
        .make-sm-column(12);
    }
}

html.floating-nav.boxed-layout {
    .navbar-wrapper {
        .container;
    }
}

html.floating-nav.fluid-layout {
    .navbar-wrapper {
        padding: 0 15px;
    }
}

#layout-main, #layout-tripel, #footer-quad {
    .row;
}

#layout-content {
    .make-sm-column(12);
}

.aside-1, .aside-2 {
    #layout-content {
        .make-sm-column(9);
    }
}

.aside-12 {
    #layout-content {
        .make-sm-column(6);
    }
}

#aside-first, #aside-second {
    .make-sm-column(3);
}

.tripel-1, .tripel-2, .tripel-3 {
    #tripel-first, #tripel-second, #tripel-third {
        .make-sm-column(12);
    }
}

.tripel-12, .tripel-13, .tripel-23 {
    #tripel-first, #tripel-second, #tripel-third {
        .make-sm-column(6);
    }
}

.tripel-123 {
    #tripel-first, #tripel-second, #tripel-third {
        .make-sm-column(4);
    }
}

.split-1, .split-2, .split-3, .split-4 {
    + #layout-footer #footer-quad-first, + #layout-footer #footer-quad-second, + #layout-footer #footer-quad-third, + #layout-footer #footer-quad-fourth {
        .make-sm-column(12);
    }
}

.split-12, .split-13, .split-14, .split-23, .split-24, .split-34 {
    + #layout-footer #footer-quad-first, + #layout-footer #footer-quad-second, + #layout-footer #footer-quad-third, + #layout-footer #footer-quad-fourth {
        .make-sm-column(6);
    }
}

.split-123, .split-124, .split-134, .split-234 {
    + #layout-footer #footer-quad-first, + #layout-footer #footer-quad-second, + #layout-footer #footer-quad-third, + #layout-footer #footer-quad-fourth {
        .make-sm-column(4);
    }
}

.split-1234 {
    + #layout-footer #footer-quad-first, + #layout-footer #footer-quad-second, + #layout-footer #footer-quad-third, + #layout-footer #footer-quad-fourth {
        .make-sm-column(3);
    }
}

html.sticky-footer {
    min-height: 100%;
    position: relative;

    #layout-footer {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .credits {
        margin-bottom: 20px;
    }
}

html.sticky-footer.boxed-layout {
    #footer {
        .container;
    }
}

html.sticky-footer.fluid-layout {
    #footer {
        padding: 0 15px;
    }
}

.carousel-control .fa-chevron-left {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    font-size: 30px;
    left: 50%;
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
}

.carousel-control .fa-chevron-right {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    font-size: 30px;
    right: 50%;
    position: absolute;
    top: 50%;
    z-index: 5;
    display: inline-block;
}

.media .comment-avatar {
    margin-right: 10px;
}

ul.comments li {
    margin-top: 40px;
}

#toTop {
    position: fixed;
    right: 5px;
    bottom: 10px;
    cursor: pointer;
    display: none;
    text-align: center;
    z-index: 500;
}

#toTop:hover {
    opacity: .7;
}

/* add user icon before menu */
.menuUserName > a:before { content: "\f007"; font-family: "FontAwesome"; margin-right: 5px; }

.widget-search-form { float: none !important; }

/* RTL Support */
html.rtl ol, html.rtl ul { padding-right: 0; }
html.rtl #toTop { right: auto; left: 5px; }

/* Orchard 1.9 layouts */
html.boxed-layout {
    .orchard-layouts-root .table {
        .container;
    }
}
html.fluid-layout {
    .orchard-layouts-root .table {
        padding: 0 15px;
    }
}
@media (max-width: @screen-xs-max) {
    .span-1.cell {
        .make-xs-column(12);
    }
    .span-2.cell {
        .make-xs-column(12);
    }
    .span-3.cell {
        .make-xs-column(12);
    }
    .span-4.cell {
        .make-xs-column(12);
    }
    .span-5.cell {
        .make-xs-column(12);
    }
    .span-6.cell {
        .make-xs-column(12);
    }
    .span-7.cell {
        .make-xs-column(12);
    }
    .span-8.cell {
        .make-xs-column(12);
    }
    .span-9.cell {
        .make-xs-column(12);
    }
    .span-10.cell {
        .make-xs-column(12);
    }
    .span-11.cell {
        .make-xs-column(12);
    }
    .span-12.cell {
        .make-xs-column(12);
    }
    .row > .cell { padding-left: 0; padding-right: 0; }
}
@media (min-width: @screen-sm-min) {
    .span-1.cell {
        .make-sm-column(1);
    }
    .span-2.cell {
        .make-sm-column(2);
    }
    .span-3.cell {
        .make-sm-column(3);
    }
    .span-4.cell {
        .make-sm-column(4);
    }
    .span-5.cell {
        .make-sm-column(5);
    }
    .span-6.cell {
        .make-sm-column(6);
    }
    .span-7.cell {
        .make-sm-column(7);
    }
    .span-8.cell {
        .make-sm-column(8);
    }
    .span-9.cell {
        .make-sm-column(9);
    }
    .span-10.cell {
        .make-sm-column(10);
    }
    .span-11.cell {
        .make-sm-column(11);
    }
    .span-12.cell {
        .make-sm-column(12);
    }
}
@media (min-width: @screen-md-min) {
    .span-1.cell {
        .make-md-column(1);
    }
    .span-2.cell {
        .make-md-column(2);
    }
    .span-3.cell {
        .make-md-column(3);
    }
    .span-4.cell {
        .make-md-column(4);
    }
    .span-5.cell {
        .make-md-column(5);
    }
    .span-6.cell {
        .make-md-column(6);
    }
    .span-7.cell {
        .make-md-column(7);
    }
    .span-8.cell {
        .make-md-column(8);
    }
    .span-9.cell {
        .make-md-column(9);
    }
    .span-10.cell {
        .make-md-column(10);
    }
    .span-11.cell {
        .make-md-column(11);
    }
    .span-12.cell {
        .make-md-column(12);
    }
}
@media (min-width: @screen-lg-min) {
    .span-1.cell {
        .make-lg-column(1);
    }
    .span-2.cell {
        .make-lg-column(2);
    }
    .span-3.cell {
        .make-lg-column(3);
    }
    .span-4.cell {
        .make-lg-column(4);
    }
    .span-5.cell {
        .make-lg-column(5);
    }
    .span-6.cell {
        .make-lg-column(6);
    }
    .span-7.cell {
        .make-lg-column(7);
    }
    .span-8.cell {
        .make-lg-column(8);
    }
    .span-9.cell {
        .make-lg-column(9);
    }
    .span-10.cell {
        .make-lg-column(10);
    }
    .span-11.cell {
        .make-lg-column(11);
    }
    .span-12.cell {
        .make-lg-column(12);
    }
}